import { PartnerSettings } from '../types/PartnerSettings';

const countrySettings: Record<string, Partial<PartnerSettings>> = {
  CA: {
    locale: 'en_CA',
    countryName: 'Canada',
    contactPhoneNumber: '+1 647 494 3370',
    currency: 'CAD',
    currencySymbol: '$',
    dataRegion: 'US',
    postcodeRegex: '^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]$',
    consentType: 'checkForExplicitMarketingConsent',
    'privacy-policy-url': 'https://www.liberis.com/privacy-policy-canada/',
    stateOptions: [
      { translationKey: 'province_alberta', value: 'Alberta' },
      {
        translationKey: 'province_british_columbia',
        value: 'British Columbia',
      },
      { translationKey: 'province_manitoba', value: 'Manitoba' },
      { translationKey: 'province_new_brunswick', value: 'New Brunswick' },
      {
        translationKey: 'province_newfoundland_and_labrador',
        value: 'Newfoundland and Labrador',
      },
      { translationKey: 'province_nova_scotia', value: 'Nova Scotia' },
      { translationKey: 'province_ontario', value: 'Ontario' },
      {
        translationKey: 'province_prince_edward_island',
        value: 'Prince Edward Island',
      },
      { translationKey: 'province_quebec', value: 'Quebec' },
      { translationKey: 'province_saskatchewan', value: 'Saskatchewan' },
    ],
    activeCustomerSettings: {
      AUTH0_ISSUER_BASE_URL: 'https://prod-liberis-external.eu.auth0.com',
      AUTH0_CLIENT_ID: 'rL8j7SDzWXdE7QfgZvlWKXrhTjjn7JTo',
    },
  },
  DE: {
    locale: 'de_DE',
    countryName: 'Germany',
    currency: 'EUR',
    currencySymbol: '€',
    contactPhoneNumber: '+498912086883',
    contactEmail: 'info-de@liberis.com',
    'privacy-policy-url':
      'https://www.liberisfinance.de/_files/ugd/b8d015_0e696b8be32a4046b6b42fe083741fec.pdf',
    postcodeRegex:
      '^0[1-9]\\d\\d(?<!0100)0|0[1-9]\\d\\d[1-9]|[1-9]\\d{3}[0-8]|[1-9]\\d{3}(?<!9999)9$',
    businessTypeOptions: [
      {
        translationKey: 'business_type_einzelkaufmann',
        value: 'Einzelkaufmann',
      },
      { translationKey: 'business_type_gmbh', value: 'GmbH' },
      { translationKey: 'business_type_mbh', value: 'mbH' },
      { translationKey: 'business_type_ug', value: 'UG' },
      { translationKey: 'business_type_gbr', value: 'GbR' },
      {
        translationKey: 'business_type_gmbh_co_kg',
        value: 'GmbH & Co. KG',
      },
      { translationKey: 'business_type_kg', value: 'KG' },
      { translationKey: 'business_type_ohg', value: 'OHG' },
    ],
    enableIntercom: false,
    'cookie-policy-url':
      'https://www.liberisfinance.de/_files/ugd/f31f8c_6fdd9c535ab844bf8a1f850e6435105d.pdf',
    'terms-and-conditions-url': '',
    showCookiePolicyApplicationFooter: true,
    activeCustomerSettings: {
      AUTH0_ISSUER_BASE_URL: 'https://prod-liberis-external.eu.auth0.com',
      AUTH0_CLIENT_ID: 'rL8j7SDzWXdE7QfgZvlWKXrhTjjn7JTo',
    },
  },
  FI: {
    locale: 'fi_FI',
    countryName: 'Finland',
    currency: 'EUR',
    currencySymbol: '€',
    postcodeRegex: '^[0-9]{5}$',
    contactEmail: 'info-nordics@liberis.com',
    activeCustomerSettings: {
      AUTH0_ISSUER_BASE_URL: 'https://prod-liberis-external.eu.auth0.com',
      AUTH0_CLIENT_ID: 'rL8j7SDzWXdE7QfgZvlWKXrhTjjn7JTo',
    },
  },
  GB: {
    locale: 'en_GB',
    countryName: 'United Kingdom',
    currency: 'GBP',
    currencySymbol: '£',
    activeCustomerSettings: {
      AUTH0_ISSUER_BASE_URL: 'https://prod-liberis-external.eu.auth0.com',
      AUTH0_CLIENT_ID: 'rL8j7SDzWXdE7QfgZvlWKXrhTjjn7JTo',
    },
  },
  PL: {
    locale: 'pl_PL',
    countryName: 'Poland',
    currency: 'PLN',
    currencySymbol: 'zł',
    currencySymbolAfterAmount: true,
    consentType: 'checkForEachMedium',
    'privacy-policy-url': 'https://www.liberis.com/polityka-prywatnosci/',
    'cookie-policy-url':
      'https://www.liberis.com/jak-korzystamy-z-plikow-cookie/',
    postcodeRegex: '^[0-9]{2}-[0-9]{3}',
    activeCustomerSettings: {
      AUTH0_ISSUER_BASE_URL: 'https://prod-liberis-external.eu.auth0.com',
      AUTH0_CLIENT_ID: 'rL8j7SDzWXdE7QfgZvlWKXrhTjjn7JTo',
    },
  },
  US: {
    locale: 'en_US',
    countryName: 'United States',
    currency: 'USD',
    currencySymbol: '$',
    dataRegion: 'US',
    dateFormat: 'MM/DD/YYYY',
    contactPhoneNumber: '+1(844) 754-2370',
    contactEmail: 'info-us@liberis.com',
    postcodeRegex: '^[0-9]{5}(-[0-9]{4})?$',
    disclosureStates: ['California', 'Utah'],
    'ace-dashboard-url': 'https://funding.us.liberis.com/login',
    'privacy-policy-url': 'https://www.liberis.com/us-privacy-policy/',
    stateOptions: [
      { translationKey: 'state_alabama', value: 'Alabama' },
      { translationKey: 'state_alaska', value: 'Alaska' },
      { translationKey: 'state_arizona', value: 'Arizona' },
      { translationKey: 'state_arkansas', value: 'Arkansas' },
      { translationKey: 'state_california', value: 'California' },
      { translationKey: 'state_colorado', value: 'Colorado' },
      { translationKey: 'state_connecticut', value: 'Connecticut' },
      { translationKey: 'state_delaware', value: 'Delaware' },
      { translationKey: 'state_florida', value: 'Florida' },
      { translationKey: 'state_georgia', value: 'Georgia' },
      { translationKey: 'state_hawaii', value: 'Hawaii' },
      { translationKey: 'state_idaho', value: 'Idaho' },
      { translationKey: 'state_illinois', value: 'Illinois' },
      { translationKey: 'state_indiana', value: 'Indiana' },
      { translationKey: 'state_iowa', value: 'Iowa' },
      { translationKey: 'state_kansas', value: 'Kansas' },
      { translationKey: 'state_kentucky', value: 'Kentucky' },
      { translationKey: 'state_louisiana', value: 'Louisiana' },
      { translationKey: 'state_maine', value: 'Maine' },
      { translationKey: 'state_maryland', value: 'Maryland' },
      { translationKey: 'state_massachusetts', value: 'Massachusetts' },
      { translationKey: 'state_michigan', value: 'Michigan' },
      { translationKey: 'state_minnesota', value: 'Minnesota' },
      { translationKey: 'state_mississippi', value: 'Mississippi' },
      { translationKey: 'state_missouri', value: 'Missouri' },
      { translationKey: 'state_montana', value: 'Montana' },
      { translationKey: 'state_nebraska', value: 'Nebraska' },
      { translationKey: 'state_nevada', value: 'Nevada' },
      { translationKey: 'state_new_hampshire', value: 'New Hampshire' },
      { translationKey: 'state_new_jersey', value: 'New Jersey' },
      { translationKey: 'state_new_mexico', value: 'New Mexico' },
      { translationKey: 'state_new_york', value: 'New York' },
      { translationKey: 'state_north_carolina', value: 'North Carolina' },
      { translationKey: 'state_north_dakota', value: 'North Dakota' },
      { translationKey: 'state_ohio', value: 'Ohio' },
      { translationKey: 'state_oklahoma', value: 'Oklahoma' },
      { translationKey: 'state_oregon', value: 'Oregon' },
      { translationKey: 'state_pennsylvania', value: 'Pennsylvania' },
      { translationKey: 'state_rhode_island', value: 'Rhode Island' },
      { translationKey: 'state_south_carolina', value: 'South Carolina' },
      { translationKey: 'state_south_dakota', value: 'South Dakota' },
      { translationKey: 'state_tennessee', value: 'Tennessee' },
      { translationKey: 'state_texas', value: 'Texas' },
      { translationKey: 'state_utah', value: 'Utah' },
      { translationKey: 'state_vermont', value: 'Vermont' },
      { translationKey: 'state_virginia', value: 'Virginia' },
      { translationKey: 'state_washington', value: 'Washington' },
      { translationKey: 'state_west_virginia', value: 'West Virginia' },
      { translationKey: 'state_wisconsin', value: 'Wisconsin' },
      { translationKey: 'state_wyoming', value: 'Wyoming' },
    ],
    activeCustomerSettings: {
      AUTH0_ISSUER_BASE_URL: 'https://prod-liberis-external.us.auth0.com',
      AUTH0_CLIENT_ID: 'FGJySQtRmxacgoySJewvdW6epjyzjmrI',
    },
  },
};

export default countrySettings;
